@import 'bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');


@mixin orientation($direction) {

  $orientation-landscape: "(orientation:landscape)";
  $orientation-portrait: "(orientation:portrait)";

  @if $direction == landscape {
    @media #{$orientation-landscape} {
      @content;
    }
  }
  @if $direction == portrait {
    @media #{$orientation-portrait} {
      @content;
    }
  }
}

html, body {
  height: 100%;
  @include media-breakpoint-between(md, lg) {
    @include orientation(landscape) {
      height: auto;
    }
  }
}

/* Background styles */
body {
  text-align: left;

  @include media-breakpoint-down(md) {
    text-align: center;

  }
  font-family: 'Roboto', sans-serif;
  @include padding($grid-gutter-width/2);
  background-image: url('https://images.unsplash.com/photo-1519681393784-d120267933ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.wrapper {
  //position: absolute;
  //top: 50%;
  //left: $grid-gutter-width/2;
  //right: $grid-gutter-width/2;
  //transform: translate(0, -50%);
  @include media-breakpoint-up(xl) {
    left: 50%;
    top: 50%;
    transform: translate(0, 75%);
  }

  @include media-breakpoint-between(md, lg) {
    @include orientation(landscape) {
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      transform: none;
    }
  }

  > div {
    margin: 10px auto;

    > div {
      @extend .col-lg-6;
      @extend .col-md-10;
      margin: 0 auto;

    }
  }
}


/* Glassmorphism card effect */
.glassmorphism {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}

.card {
  @extend .glassmorphism;
  margin: 10px auto;
  padding: $grid-gutter-width/2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .card-title {
    margin-bottom: 20px;
  }
}

.profile-card {
  .img-thumbnail-wrapper {
    @include media-breakpoint-down(md) {
      margin: auto;
    }
  }

}

h1, h2 {
  margin: 0 0 10px;
  padding: 0;
  line-height: 1;
  font-weight: 500;
}

h1 {
  @include media-breakpoint-up(md) {
    font-size: 50px;
  }
}

h2 {
  font-size: 20px;
  margin-bottom: 20px;
  @include media-breakpoint-up(md) {
    font-size: 30px;
  }
}

.social-links {
  margin-bottom: 30px;

  img {
    margin: 5px;
    width: 30px;
    height: 30px;
  }
}

.skill-stacks {
  img {
    width: 30px;
    height: 30px;
    margin: 5px;
    @include media-breakpoint-up(sm) {
      width: 40px;
      height: 40px;
      margin: 10px;
    }
  }
}

.mr-2 {
  margin-right: 20px;
}

.weblink {
  @include media-breakpoint-up(lg) {
    display: flex;
  }

  .btn {
    justify-content: space-between;
    margin-right: 5px;
    color: #000 !important;

    &:hover {
      color: #FFF !important;
    }
  }
}